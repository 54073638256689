/* General Table Styles */
.LawyerAssignmentTable-scroll table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #dee2e6; /* Light border around the table */
  background-color: #ffffff; /* Clean white background */
  border-radius: 8px; /* Rounded corners */
  overflow:auto; /* Clip content for rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Header Styles */
.LawyerAssignmentTable-scroll thead th {
  position: sticky;
  top: 0;
  background-color: #f1f3f5; /* Light gray background for header */
  color: #212529; /* Dark text for contrast */
  font-weight: bold;
  text-transform: uppercase;
  padding: 10px 15px;
  z-index: 2;
  border-bottom: 2px solid #dee2e6; /* Define separation line */
  text-align: center;
  vertical-align: middle;
}

/* Body Styles */
.LawyerAssignmentTable-scroll tbody td {
  padding: 10px 15px; /* Add consistent spacing */
  vertical-align: middle;
  color: #495057; /* Neutral dark text */
  font-size: 14px; /* Standard font size */
  border-bottom: 1px solid #dee2e6; /* Subtle row separation */
  text-align: center;
}

/* Hover Effects for Rows */
.LawyerAssignmentTable-scroll tbody tr:hover {
  cursor: pointer; /* Pointer cursor for interactive feel */
}

/* Link Styling Inside Cells */
.LawyerAssignmentTable-scroll tbody td a {
  text-decoration: none; /* Remove underline */
  font-weight: 500; /* Slightly bolder text */
}

.LawyerAssignmentTable-scroll tbody td a:hover {
  text-decoration: underline; /* Underline on hover for clarity */
}

/* Footer Styles */
.LawyerAssignmentTable-scroll tfoot td {
  background-color: #f1f3f5; /* Match header background */
  color: #212529;
  font-weight: bold;
  padding: 10px 15px;
  text-align: right;
}

/* Scrollbar Placement */
.LawyerAssignmentTable-scroll {
  position: relative;
  max-height: 480px;
  overflow-x: auto; /* Horizontal scrolling */
  overflow-y: auto; /* Vertical scrolling */
}
.LawyerAssignmentTable-scroll.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.LawyerAssignmentTable-scroll::-webkit-scrollbar {
  height: 10px; /* Horizontal scrollbar size */
  width: 10px; /* Vertical scrollbar size */
}

.LawyerAssignmentTable-scroll::-webkit-scrollbar-thumb {
  background-color: #adb5bd; /* Subtle gray for thumb */
  border-radius: 5px;
}

.LawyerAssignmentTable-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #6c757d; /* Slightly darker on hover */
}

.LawyerAssignmentTable-scroll::-webkit-scrollbar-track {
  background-color: #e9ecef; /* Light background for track */
  border-radius: 5px;
}
