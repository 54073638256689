/*!
 * Template Name: NioBoard
 * Author: Softnio
 * Author URI: http://themeforest.net/user/softnio
 * Version: 1.0.1
 * Updated: 06.13.2023
**/

/** IMPORTANT NOTE: DO NOT Edit this file. Best to write own code in css/theme.css file. **/

/* 
----------------------
## Table Contents ##
---------------------- 
**/

////////////////////////// DO NOT REMOVE BELOW ///////////////////////

// Included Global Variables and Functions
@import "core/functions";
@import "vendors/nioicon/variables";
@import "variables";
@import "extend/bootstrap/variables";

// Included Fonts
@import "core/fonts";

// Included Bootstarp
@import "extend/bootstrap/bootstrap";
@import "extend/bootstrap/extend";

// Include Other Vendors
@import "vendors/bundle";

// Include Core Style
@import "core/style";

// Include prism
@import "vendors/prism-theme";

// Include highlight
@import "core/preview";

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";